import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Grid, Card, CardHeader, CardContent, CardMedia, Badge, TextField, ToggleButton} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';



import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function App() {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [expandedDarkon, setExpandedDarkon] = React.useState(false);
  
  const handleExpandDarkon = () => {
    setExpandedDarkon(!expandedDarkon);
  };

  const [expandedVisa, setExpandedVisa] = React.useState(false);
  
  const handleExpandVisa = () => {
    setExpandedVisa(!expandedVisa);
  };

  const [expandedPass, setExpandedPass] = React.useState(false);
  
  const handleExpandPass = () => {
    setExpandedPass(!expandedPass);
  };

  const [expanded, setExpanded] = React.useState(false);
  
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
    <div className="App">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ background: '#2E3B55' }}>
          <Toolbar>
            
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              זימון תורים
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

      <List
      sx={{
        width: '100%',
        maxWidth: '100%',
        bgcolor: 'background.paper',
      }}
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <EmailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="אימייל" secondary="service@zimun-torim.com" />
      </ListItem>
      
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <WhatsAppIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="וואטסאפ" secondary="052-622-6406" />
      </ListItem>
      
      
    </List>

      </div>
      <div className="App-content">

      
    

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
        <Tab label="סוגי תורים" {...a11yProps(0)} />
        <Tab label="איך זה עובד?" {...a11yProps(1)} />
        {/* <Tab label="אודותינו" {...a11yProps(2)} /> */}
      </Tabs>
    </Box>
    <TabPanel value={value} index={0} style={{textAlign: "center"}}>
    
      <Grid container
  direction="row"
  justifyContent="center"
  alignItems="flex-start" spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 8, md: 12 }} style = {{marginTop: 10, textAlign: "center"}}>        
     
              <Grid item xs={2} sm={3} md={3} key={0} style={{textAlign: "center"}}>
                <Card>
                  <CardMedia
                    component="img"
                    height="300"
                    image="/darkon.jpg"
                    alt="דרכון">
                    
                  </CardMedia>  

                  <CardHeader
                    title={"דרכון"}
                    subheader={"תור לחידוש או הנפקת דרכון"}
                  />
                  <CardContent>  
                  <a href="https://forms.gle/HqEFSM1yqdpJGoKT7" target="_blank">מלא טופס</a>                
                  </CardContent>
                  <CardActions disableSpacing>
                  <ExpandMore
                    expand={expandedDarkon}
                    onClick={handleExpandDarkon}
                    aria-expanded={expandedDarkon}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedDarkon} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography paragraph>מה אנחנו צריכים כדי שנוכל לקבוע לכם את התור?</Typography>
                      
                      <div className="Center">
                        <ul>
                          <li>שם</li>
                          <li>מספר תעודת זהות</li>
                          <li>מספר טלפון שמקבל סמס</li>
                          <li>סניף</li>
                        </ul>
                      </div>
                      
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>

              
              <Grid item xs={2} sm={3} md={3} key={1}>
                <Card>
                  <CardMedia
                    component="img"
                    height="300"
                    image="/visa.jpg"
                    alt="ןיזה">
                    
                  </CardMedia>  

                  <CardHeader
                    title={"ויזה לארצות הברית"}
                    subheader={"תור לחידוש או הנפקת ויזה"}
                  />
                  <CardContent>  
                  <a href="https://forms.gle/xK5FFRzAyj2XrjCi9" target="_blank">מלא טופס</a>                
                  </CardContent>
                  <CardActions disableSpacing>
                  <ExpandMore
                    expand={expandedVisa}
                    onClick={handleExpandVisa}
                    aria-expanded={expandedVisa}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedVisa} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography paragraph>מה אנחנו צריכים כדי שנוכל לקבוע לכם את התור?</Typography>
                      
                      <div className="Center">
                        <ul>
                        <li>שם משתמש וסיסמה למערכת השגרירות שבה קובעים את התורים</li>
                        </ul>
                      </div>
                      
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
              <Grid item xs={2} sm={3} md={3} key={2}>
                <Card>
                  <CardMedia
                    component="img"
                    height="300"
                    image="/id.jpg"
                    alt="תעודת זהות">
                    
                  </CardMedia>  

                  <CardHeader
                    title={"תעודת זהות"}
                    subheader={"תור לחידוש או הנפקת ת.ז"}

                    
                  />
                  <CardContent>  
                  <a href="https://forms.gle/HqEFSM1yqdpJGoKT7" target="_blank">מלא טופס</a>                
                  </CardContent>
                  <CardActions disableSpacing>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                  </CardActions>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography paragraph>מה אנחנו צריכים כדי שנוכל לקבוע לכם את התור?</Typography>
                      
                      <div className="Center">
                        <ul>
                          <li>שם</li>
                          <li>מספר תעודת זהות</li>
                          <li>מספר טלפון שמקבל סמס</li>
                          <li>סניף</li>
                        </ul>
                      </div>
                      
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
              <Grid item xs={2} sm={3} md={3} key={3}>
                <Card>
                  <CardMedia
                    component="img"
                    height="300"
                    image="/uspassport.jpg"
                    alt="דרכון אמריקאי">
                    
                  </CardMedia>  

                  <CardHeader
                    title={"דרכון אמריקאי"}
                    subheader={"תור לחידוש או הנפקת דרכון אמריקאי"}
                    
                  />
                  <CardContent>                  
                  <a href="https://forms.gle/T8NK1Mb8cDUAqyVv9" target="_blank">מלא טופס</a>                
                  
                  </CardContent>
                  <CardActions disableSpacing>
                  <ExpandMore
                    expand={expandedPass}
                    onClick={handleExpandPass}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedPass} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography paragraph>מה אנחנו צריכים כדי שנוכל לקבוע לכם את התור?</Typography>
                      
                      <div className="Center">
                        <ul>
                        <li>שם פרטי ומשפחה (באנגלית)</li>
                        <li>תאריך לידה</li>
                        <li>כתובת אימייל</li>
                        <li>מספר טלפון</li>
                        <li>שמות מלוים - אם יש (באנגלית)</li>

                        </ul>
                      </div>
                      
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>  


                    

          </Grid>
          
    </TabPanel>
    <TabPanel value={value} index={1}>
    בחרו את סוג התור בטאב "סוגי תורים", לחצו על החץ בצד ימין למטה כדי לראות את רשימת הפרטים הרלוונטיים לסוג התור ואז או לחצו על "מלא טופס" או שילחו אותם אלינו לוואטסאפ/אימייל המופיעים בראש העמוד. 
אנו נמלא עבורכם את כל הטפסים והמסמכים הרלוונטיים ונתחיל לחפש עבורכם תור קרוב, אחרי שנקבע לכם תור בהצלחה תקבלו הודעת סמס או אימייל ממשרד הפנים/השגרירות עם פרטי התור.
    </TabPanel>
    {/* <TabPanel value={value} index={2}>
    המצב העגום היום בו צריך להמתין חודשים ולפעמים למעלה משנה בשביל דרכון, תעודת זהות, ויזה ועוד.. דירבן אותנו להקים את מיזם זימון תורים בו אנשים חרוצים ומהירים יקומו במקומכם בשבע בבוקר או ישארו ערים עד מאוחר בלילה, ובמהלך היום ישבו במקומכם על מערכות התורים השונות ויחכו לזמן הקצר בו נפתחים תורים ויקבעו אותם עבורכם.. בינתיים אנחנו קובעים בהצלחה תורים עבור תעודות זהות ודרכונים במשרד הפנים, עבור ויזה ושירותים נוספים בשגרירויות ונשמח לשמוע על עוד גופים עם זמני תורים הזויים ולסייע גם בהם.
    </TabPanel> */}
    </div>
    </div>
  );
}

export default App;
